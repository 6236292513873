@import '~bootstrap';

.postcodenl-autocomplete {
    &-menu {
        @extend .dropdown-menu;

        &-open {
            display: block;
        }

        &-items {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    &-item {
        cursor: pointer;
        @extend .dropdown-item;

        mark {
            font-weight: bold;
            background: none;
            padding: 0;
            color: inherit;
        }

        &-focus:not(:hover) {
            .postcodenl-autocomplete-item-description {
                color: $white;
            }

            @extend .active;
        }
    }

    &-loading + .spinner-grow {
        display: block !important;
    }

    &-item-description {
        color: $gray-600;
        @extend .small;
        @extend .ms-2;
    }

    &-aria-live-region {
        @extend .visually-hidden;
    }
}
