.signature-recorder {
    &-text {
        white-space: pre-line;
        padding: 1rem;
    }

    .form-control {
        max-width: 510px;
        width: 100%;
        height: auto;
        padding: 0;
        background-image: none;

        &:focus-within {
            border-color: rgb(128, 189, 255);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }

    audio {
        display: block;
        width: 100%;
        height: 57px;
        border-top: 1px solid #ced4da;
        outline: none;

        &::-webkit-media-controls-panel {
            background: #fff;
        }
    }
}
