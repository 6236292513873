@import './../../../../../../../../index';

.ideal-qr {
    @extend .form-control;
    height: auto;
    padding: 1rem;
    text-align: center;

    &.is-invalid {
        background-image: none;
        padding-right: 1rem;
    }

    &-footer {
        @include font-size($font-size-base * 0.7);
        max-width: 20rem;
        margin: 0 auto;
    }

    &-status {
        @include font-size($font-size-base * 0.7);
        margin: 1rem 0 0;
    }

    &-check {
        background: $gray-100;
        height: 4rem;
        width: 4rem;
        line-height: 4rem;
        font-size: 3rem;
        color: $success;
        border-radius: 100%;
    }

    &-container {
        width: 16rem;
        height: 16rem;
        border: 1px dotted $input-border-color;
        border-radius: $input-border-radius;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 1rem auto;
        text-align: center;

        img {
            width: 100%;
        }

        input.form-control {
            max-width: 240px;
            text-align: center;
            padding-right: 1rem;
        }
    }
}
