@import '../../../../../../../../index';

.signature-verification-sms {
    @extend .form-control;
    max-width: 510px;
    height: auto;
    padding: 1rem;

    &.is-invalid {
        background-image: none;
        padding-right: 1rem;
    }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2rem 1rem;
        text-align: center;

        input.form-control {
            max-width: 240px;
            text-align: center;
            padding-right: 1rem;
        }
    }

    &-footer {
        @include font-size($font-size-base * 0.7);
    }
}
