.wizard {
    &-header {
        background: white;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        .d-flex {
            min-height: 56px;
        }
    }
    &-logo {
        max-height: 56px;
        max-width: 320px;
    }

    &-backdrop {
        background-color: #ddd;
        color: white;
        text-align: center;
        background-size: cover;
        background-position: 50% 50%;
        min-height: calc(100vh - 97px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &-content {
            position: relative;
            max-width: 600px;
        }
        &-effect {
            background: black;
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}
