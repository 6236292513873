hr.hr-text {
    position: relative;
    border: none;
    height: 1px;
    overflow: visible;
    opacity: 1;
    background: #ced4da;
}

hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fbfbfb;
    padding: 0rem 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
