@import '../../index';

.btn {
    position: relative;
    span.spinner-grow {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -8px;
    }
    span.spinner-grow + span {
        opacity: 0;
    }
}

.form-check {
    margin: 7px 0;
}

.is-invalid {
    select,
    input {
        border-color: $red;
    }
}

.form-group {
    .spinner-grow {
        position: absolute;
        margin: 8px 1rem 0 0;
        top: 50%;
        right: 0;
    }
    .input-group {
        & > .input-group-w50 {
            flex: 0 0 50px;
        }
        & > .input-group-w75 {
            flex: 0 0 75px;
        }
        & > .input-group-w100 {
            flex: 0 0 100px;
        }
    }
}
