.signature-canvas {
    .form-control,
    .form-control.is-invalid {
        padding: 0;
        overflow: hidden;
        height: auto;
        max-width: 510px;
        min-width: 240px;
        width: 100%;
    }
    canvas {
        display: block;
        width: 100%;
    }
}
