@import '~bootstrap';

.home-entries-card {
    td,
    th {
        vertical-align: middle;
    }
    td:first-child,
    th:first-child {
        padding-left: 1.25rem;
    }

    td:last-child,
    th:last-child {
        padding-right: 1.25rem;
    }

    .card-body + .card-body {
        padding-top: 0;
    }

    .table {
        tbody tr:last-child td {
            border-bottom: 0;
        }
        thead tr:last-child th {
            border-color: inherit;
            border-bottom-width: 2px;
            border-top-width: 1px;
        }
    }
}
