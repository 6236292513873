@use 'sass:math';

$toast-background-color: rgba(white, 0.95);
$table-cell-padding-y: 0.75rem;
$card-spacer-y: 1.25rem;
$card-spacer-x: 1.25rem;

@import '~bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body,
html {
    background: #f9fafb !important;
    font-family: 'Inter', sans-serif;
}

.m-gutter {
    margin: math.div($grid-gutter-width, 2);
}

.toast {
    max-width: 400px;
}
  
#entries tr {
    border-bottom: 1px solid #ddd;
}

.disable-selection {
    user-select: none;
    -webkit-user-select: none; /* disable selection/Copy of UIWebView */
    -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
}

@media screen and (max-width: 550px) {
    #entries td {
        @apply tw-block tw-text-[0.8em] tw-text-right tw-border-b-[#ddd] tw-border-b;
    }

    #entries td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }
    
    #entries td:last-child {
      border-bottom: 0;
    }

    #entries tbody td {
        @apply xsmax:tw-py-1;
    }
}
